<template>
    <div>
      <div v-if="editnote_dimensione_btn == 'block'">
        <b-button
          variant="outline-primary"
          class="mb-1" block
          @click="modificaNote(editnote_id,editnote_note)"
        >
          <feather-icon icon="Edit2Icon" /> Modifica Note
        </b-button>
      </div>
      <div v-else>
        <b-button
          variant="outline-primary"
          class="mb-1"
          @click="modificaNote(editnote_id,editnote_note)"
        >
          <feather-icon icon="Edit2Icon" /> Modifica Note
        </b-button>
      </div>

      <b-modal
        ref="editnote-modal"
        size="lg"
        centered
        hide-footer
        title="Modifica Note"
        @hide="resetEditNotemodal"
      >
        <b-form>
          <b-form-textarea 
            id="textarea-note"
            rows="6"
            v-model="campiformOpenEditNote.note"
          />
          
          <div class="pt-1">
            <b-button
              variant="primary"
              type="submit"
              :disabled="action_press_edit_note"
              @click.prevent="salvaNote()"
            >
              <div v-if="action_press_edit_note">Salvaggio in corso <b-spinner small class="ml-1" /></div>
              <div v-else>Salva</div> 
            </b-button>
          </div>
        </b-form>        
      </b-modal>

    </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BAlert, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BFormInput, BButton, BSidebar, VBToggle, BBadge, BFormTextarea, BTable, BMediaBody, BMedia, BMediaAside, BAvatar, BOverlay, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAlert, 
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BFormGroup,
    BForm, 
    BFormInput, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BFormTextarea, 
    BTable, 
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar, 
    BOverlay,

  },
  props: {
    editnote_id: '',
    editnote_note: '',
    editnote_dimensione_btn: '',
    editnote_atc_aggiorna: '',
  },
  data() {
    return {
      campiformOpenEditNote:{
        id_riga: '',
        note: '',
      },
      action_press_edit_note: false,
    }
  },
  methods: {
    modificaNote(id_riga, note){      
      this.campiformOpenEditNote.id_riga = id_riga;
      this.campiformOpenEditNote.note = note

      this.$refs['editnote-modal'].show()

    },
    resetEditNotemodal(){
      this.campiformOpenEditNote = {
        id_riga: '',
        note: '',
      }
    },
    salvaNote(){
      this.$http.post('api/crm/customer/update_note', 
        this.campiformOpenEditNote
      ).then(response => { 

        //chiudi finestra
        this.$refs['editnote-modal'].hide();

        this.$swal({
          icon: 'success',
          title: response.data.reply.reply,
          //text: 'operazione effettuata correttamente',
          confirmButtonText: 'chiudi',
          customClass: {
          confirmButton: 'btn btn-success',
          },
        })

        if(this.editnote_atc_aggiorna == 'si'){
          //aggiorna note sulla pagina tramite un evento
          this.$emit('campoNoteCustomerAggiornato', response.data.reply.note);
        }

      }).catch(e => {
        console.log(e);
      });
    }
  }

}
</script>